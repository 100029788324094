export const useTaxRentStore = defineStore('tax-rent-store', () => {
  const unitRentPerWeek = ref<number>(0)
  const taxPercent = ref<number>(0)
  const { convertToCentNumber } = useMoneyHelper()
  async function getUnitRent(): Promise<void> {
    if (unitRentPerWeek.value > 0) return
    try {
      const { data } = await useFetch(() => '/api/rent')

      if (!data.value) throw new Error('Error fetching rent amount')

      unitRentPerWeek.value = convertToCentNumber(Number(data.value) || 0)
    } catch (error) {
      console.error(error)
    }
  }
  async function getTaxPercent(): Promise<void> {
    if (taxPercent.value > 0) return
    try {
      const { data } = await useFetch(() => '/api/tax')

      if (!data.value) throw new Error('Error fetching tax amount')

      taxPercent.value = data.value as number
    } catch (error) {
      console.error(error)
    }
  }

  return {
    unitRentPerWeek, // in cents
    getUnitRent,
    taxPercent,
    getTaxPercent,
  }
})
