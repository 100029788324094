<template>
  <div class="mt-4">
    <v-row dense class="my-2">
      <v-col cols="12">
        <SheetForButton
          height="50px"
          class="align-content-center cursor-pointer"
          @click="openSidebar(ACTIVE_SIDEBAR_TYPE.ZIP)">
          <v-row justify="space-between" class="h-100">
            <v-col cols="1" class="pl-2">
              <v-icon>custom:inputcorrect</v-icon>
            </v-col>
            <v-col cols="5" class="h-100 pr-md-0 pr-lg-3">
              <span class="font-weight-bold">{{ t('delivery_to') }}</span>
            </v-col>
            <v-col cols="6" class="text-right pl-md-0 pl-lg-3">
              <span>
                {{ newZipcode !== '' ? newZipcode : currentCity?.zip }} {{ activeCity }}
                <v-icon class="pr-0 mr-0">mdi-chevron-right</v-icon>
              </span>
            </v-col>
          </v-row>
        </SheetForButton>
      </v-col>
      <v-col cols="12" class="mt-2">
        <SheetForButton
          height="50px"
          class="align-content-center"
          @click="openSidebar(ACTIVE_SIDEBAR_TYPE.FRACTION)">
          <v-row justify="space-between">
            <v-col cols="1" class="pl-2">
              <v-icon>custom:inputcorrect</v-icon>
            </v-col>
            <v-col cols="4">
              <span class="font-weight-bold">{{ t('select_waste_type_short') }}</span>
            </v-col>
            <v-col cols="7" class="text-right">
              <v-row>
                <v-col cols="12" class="pr-0 pl-0 flex-row">
                  <span class="limited">
                    {{ fraction?.description }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </SheetForButton>
      </v-col>
    </v-row>
    <v-row dense class="mb-2">
      <v-col cols="12">
        <SheetForButton
          height="50px"
          class="align-content-center"
          @click="openSidebar(ACTIVE_SIDEBAR_TYPE.CONTAINER)">
          <v-row>
            <v-col cols="1" class="pl-2">
              <v-icon>custom:inputcorrect</v-icon>
            </v-col>
            <v-col cols="5">
              <span class="font-weight-bold">Container</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <span>
                <!-- enslint-disable-next-line vue/html-indent -->
                <!-- prettier-ignore -->
                {{
                  selectedContainer > 0
                    ? activeOffers?.find(container => 
                      container.directusContainerId === selectedContainer)?.size
                    : t('select')
                }}
                <v-icon class="pr-0 mr-0">mdi-chevron-right</v-icon>
              </span>
            </v-col>
          </v-row>
        </SheetForButton>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-row dense>
          <v-col
            v-for="productContainer in activeOffers"
            :key="productContainer.directusContainerId"
            cols="4"
            sm="3"
            md="6"
            lg="4"
            xl="3">
            <CdeCard
              rounded="small"
              min-height="50px"
              :class="{
                'align-content-center': true,
                'active-container': selectedContainer === productContainer.directusContainerId,
              }"
              hover
              @click="selectProduct(productContainer.directusContainerId)">
              <v-row>
                <v-col cols="12">
                  <div class="py-0 position-relative product-icon">
                    <div
                      v-if="selectedContainer === productContainer.directusContainerId"
                      class="pt-3">
                      <v-img
                        v-if="productContainer.productSize > 10"
                        src="@/assets/icons/active-big-container.svg"
                        :alt="t('roll_off_container')"
                        height="40px" />
                      <v-img
                        v-else
                        src="@/assets/icons/active-small-container.svg"
                        height="40px"
                        :alt="t('settling_container')" />
                    </div>

                    <div v-else class="pt-3">
                      <v-img
                        v-if="productContainer.productSize > 10"
                        src="@/assets/icons/big-container.svg"
                        :alt="t('roll_off_container')"
                        height="40px" />
                      <v-img
                        v-else
                        src="@/assets/icons/small-container.svg"
                        height="40px"
                        :alt="t('settling_container')" />
                    </div>
                    <span class="font-weight-bold pl-2 d-block position-absolute">
                      {{ productContainer.size }}
                    </span>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0 text-center px-0">
                  <span class="font-weight-bold pl-2 sample">
                    {{ formatCentToEuro(productContainer.unitPrice.grossPrice) }}
                    &euro;
                  </span>
                </v-col>
              </v-row>

              <v-divider />

              <v-row>
                <v-col cols="12" class="text-center cubic-price">
                  <span>
                    {{ formatCentToEuro(productContainer.unitPrice.volumePrice) }}&euro;/m³
                  </span>
                </v-col>
              </v-row>
            </CdeCard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <SheetForButton
          height="50px"
          class="align-content-center"
          :class="{ 'border-sm border-error': noDeliveryDateOrWrongFormat && validatedOnce }"
          @click="openSidebar(ACTIVE_SIDEBAR_TYPE.DATE)">
          <v-row justify="space-between">
            <v-col cols="1" class="pl-2">
              <v-icon v-if="desiredDateString === ''">custom:overhere</v-icon>
              <v-icon v-if="desiredDateString !== ''">custom:inputcorrect</v-icon>
            </v-col>
            <v-col cols="4" xl="5">
              <span class="font-weight-bold">{{ t('delivery_date') }}</span>
            </v-col>

            <v-col cols="7" xl="6" class="text-right">
              <span color="text">
                <span
                  :class="{
                    'too-long': desiredDateString !== '',
                    'text-decoration-underline': desiredDateString === '',
                  }">
                  {{ desiredDateString !== '' ? desiredDateString : t('select') }}
                </span>
                <v-icon class="pr-0 mr-0" :color="desiredDateString === '' ? 'primary' : 'text'">
                  mdi-chevron-right
                </v-icon>
              </span>
            </v-col>
          </v-row>
        </SheetForButton>
      </v-col>
    </v-row>
    <v-row dense class="mt-4 px-0">
      <v-col cols="12" class="px-0">
        <v-row justify="space-between" class="pt-1" align="center">
          <v-col cols="auto" md="4" lg="auto" class="pr-0 pl-1 pb-0">
            <span class="font-weight-bold text-h5">{{ t('price') }}</span>
            <span>&nbsp;{{ t('include_vat') }}</span>
          </v-col>
          <v-col cols="auto" md="8" lg="auto" class="text-right pl-0 pr-1 pb-0">
            <span class="font-weight-bold text-h4">
              {{
                selectedOffer?.unitPrice.grossPrice
                  ? formatCentToEuro(selectedOffer?.unitPrice.grossPrice)
                  : '0,00'
              }}&euro;
            </span>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="pb-1">
          <v-col cols="auto" class="py-0 pr-0 pl-1">
            <span>{{ t('plus_rental') }}</span>
          </v-col>
          <v-col cols="auto" class="text-right pl-0 pr-1 py-0">
            <span>{{ formatCentToEuro(productRentAmount.gross) }}&euro;</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="pb-6 pb-md-3">
      <v-col cols="12" class="mt-4">
        <v-tooltip top :disabled="disableToolTip" open-on-click>
          <template #activator="{ props }">
            <div v-bind="props">
              <ButtonPrimary
                class="add-to-cart"
                width="100%"
                min-height="80px"
                @click="addItemToCart">
                {{ t('add_to_cart') }}
              </ButtonPrimary>
            </div>
          </template>

          <template v-if="currentContainerHasReachedMaxQuantity">
            <div class="py-4 px-3">
              {{ t('max_containers_reached') }}
            </div>
          </template>

          <template v-else-if="noDeliveryDateOrWrongFormat">
            <div class="py-4 px-3">
              {{ t('please_chose_delivery_date_range') }}
            </div>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="mt-6" dense>
      <v-col cols="12">
        <VSheet rounded min-height="50px" class="align-content-center sample py-2 px-0" border>
          <v-row
            justify="space-between"
            dense
            class="pt-1 pb-2 cursor-pointer"
            @click="openSidebar(ACTIVE_SIDEBAR_TYPE.SPACE_REQUIREMENT)">
            <v-col cols="auto" lg="6" class="pr-0">
              <span class="font-weight-bold pl-2">
                <v-icon class="pb-1 mr-3 mr-md-1 mr-lg-2" size="18px">custom:truck</v-icon>
                {{ t('space_requirements') }}
              </span>
            </v-col>

            <v-col cols="auto" lg="6" class="text-right pl-md-0 pl-lg-3">
              <span>
                <span>
                  {{ 'z.B. Rangierfläche' }}
                </span>
                <v-icon class="pr-0 mr-0">mdi-chevron-right</v-icon>
              </span>
            </v-col>
          </v-row>
          <v-divider class="mx-3" />
          <v-row
            justify="space-between"
            dense
            class="pt-2 pb-1 cursor-pointer"
            @click="openSidebar(ACTIVE_SIDEBAR_TYPE.RULES)">
            <v-col cols="auto" lg="6" class="pr-0">
              <span class="font-weight-bold pl-1">
                <v-icon class="pl-1 pb-1 mr-3 mr-md-1 mr-lg-3" size="18px">custom:trashcan</v-icon>
                {{ t('allowed_waste') }}
              </span>
            </v-col>
            <v-col cols="auto" md="5" lg="6" class="text-right pr-0 pl-md-0 pl-lg-3">
              <v-row justify="end">
                <v-col cols="10" class="px-0">
                  <span class="limited">
                    {{ t('for_example') }}
                    {{ fraction?.allowed_items![0].split(',')[0] }}
                  </span>
                </v-col>
                <v-col class="pr-1p5 d-flex align-center justify-end" cols="2">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </VSheet>
      </v-col>
    </v-row>

    <ComponentSidebar
      :title="activeSideBarTitle"
      :persistent="
        !(
          currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.SPACE_REQUIREMENT ||
          currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.RULES
        )
      ">
      <SidebarWasteRule
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.RULES"
        :fraction="fraction || null" />

      <SidebarChangePostcode
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.ZIP"
        @zip-code-entered="confirmZipcode" />

      <SidebarChooseFraction v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.FRACTION" />

      <SidebarChooseContainer
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.CONTAINER"
        :selected-container="selectedContainer"
        @container-change="selectProduct" />

      <SidebarPlaceRequirement
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.SPACE_REQUIREMENT"
        :place-requirement="fraction?.place_requirement ?? ''" />

      <SidebarSelectDate
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.DATE"
        :selected-dates="deliveryDate"
        :selected-placement-time="containerPlacementTime"
        @dates-confirmed="gatherDates" />
    </ComponentSidebar>

    <v-overlay v-model="priceChangeByReason.flag" class="align-center justify-center">
      <HeroPriceChangeOverlay
        :reason="priceChangeByReason.reason"
        :updated-offers="priceDiffObject"
        :selected-container="selectedContainer"
        @accept-new-price="acceptNewPrice"
        @decline-new-price="declineNewPrice" />
    </v-overlay>
    <OverlayDifferentZipcode
      v-model="showCustomerOrdersWithDifferentZipModal"
      :products="cart.products"
      :current-cart-delivery-address="cart.customerDetails.deliveryAddress" />
  </div>
</template>

<script setup lang="ts">
import {
  ACTIVE_SIDEBAR_TYPE,
  CONTAINER_DELIVERY_TIME_FRAME,
  DateStringOptions,
} from '~/types/enums'
import type { City, Container, ContainerOffer, OrderDatePayload, Product } from '~/types/types'

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24
const { t } = useI18n()
const fractionStore = useFractionStore()
fractionStore.getFractionData()

const sidebarStore = useSidebarStore()
const { currentComponentSidebarType } = storeToRefs(sidebarStore)

const { fractions } = storeToRefs(fractionStore)
const containerStore = useContainerStore()
containerStore.getContainerData()
const { containers } = storeToRefs(containerStore)
const offerStore = useOfferStore()
const cartStore = useCartStore()
cartStore.getCart()
const { cart } = storeToRefs(cartStore)
const { MAX_PRODUCT_QUANTITY } = cartStore

const taxRentStore = useTaxRentStore()
taxRentStore.getUnitRent()
taxRentStore.getTaxPercent()
const { unitRentPerWeek, taxPercent } = storeToRefs(taxRentStore)
const { convertToCentNumber, calculatePriceWithouVatInCent, formatCentToEuro } = useMoneyHelper()

const { currentFractionId, currentOffers, currentCity } = storeToRefs(offerStore)
//will use the fraction for empto request
const newFractionId = ref(currentFractionId.value)
const newOffers = ref([] as ContainerOffer[])
const newCity = ref('')
const newZipcode = ref('')
const newCommunity = ref<number>()
const { findOffer } = useApiWrapper()

const fraction = computed(() =>
  fractions.value?.find(fraction => fraction.id === currentFractionId.value),
)
const selectedContainer = ref<number>(0)
const selectedOffer = ref<ContainerOffer | null>(null)

const activeSidebarType = ref<ACTIVE_SIDEBAR_TYPE>(ACTIVE_SIDEBAR_TYPE.ZIP) // default to ZIP

const priceDiffObject = ref<ContainerOffer[]>([])

const sidebarTitles: Record<ACTIVE_SIDEBAR_TYPE, string> = {
  [ACTIVE_SIDEBAR_TYPE.ZIP]: t('change_postcode'),
  [ACTIVE_SIDEBAR_TYPE.FRACTION]: t('change_waste_type'),
  [ACTIVE_SIDEBAR_TYPE.CONTAINER]: t('select_container'),
  [ACTIVE_SIDEBAR_TYPE.DATE]: t('select_date'),
  [ACTIVE_SIDEBAR_TYPE.SPACE_REQUIREMENT]: t('space_requirement'),
  [ACTIVE_SIDEBAR_TYPE.RULES]: t('allowed_waste'),
  [ACTIVE_SIDEBAR_TYPE.CART]: t('cart'),
}
const selectProduct = (containerId: number) => {
  selectedContainer.value = containerId

  selectedOffer.value =
    currentOffers.value?.find(container => container.directusContainerId === containerId) || null
}

const showCustomerOrdersWithDifferentZipModal = ref(false)

const validatedOnce = ref(false)
const cartHasProductWithDifferentZip = computed(() => {
  return (
    cart.value.products.length > 0 &&
    cart.value.customerDetails.deliveryAddress.postalCode &&
    cart.value.customerDetails.deliveryAddress.postalCode !== currentCity.value?.zip
  )
})

const desiredDateString = ref('')
const deliveryDate = ref<Date[]>([])
const containerPlacementTime = ref<CONTAINER_DELIVERY_TIME_FRAME>(
  CONTAINER_DELIVERY_TIME_FRAME.WHOLE_DAY,
)

const openSidebar = (sidebarType: ACTIVE_SIDEBAR_TYPE) => {
  sidebarStore.openComponentSidebar(sidebarType)
  activeSidebarType.value = currentComponentSidebarType.value || sidebarType
}

const activeSideBarTitle = computed(() => sidebarTitles[activeSidebarType.value] || '')

const gatherDates = (payload: OrderDatePayload) => {
  desiredDateString.value = `${payload.deliveryDate[0].toLocaleDateString('de-DE', DateStringOptions)} - ${payload.deliveryDate[payload.deliveryDate.length - 1].toLocaleDateString('de-DE', DateStringOptions)}`
  deliveryDate.value = payload.deliveryDate
  containerPlacementTime.value = payload.containerPlacementTime as CONTAINER_DELIVERY_TIME_FRAME
}

const confirmZipcode = (cityInput: City) => {
  if (!cityInput.zip || cityInput.zip === '' || cityInput.zip === null) {
    offerStore.setCurrentOffers([])
    acceptNewPrice()
    return
  }
  newCity.value = cityInput.name
  newZipcode.value = cityInput.zip
  newCommunity.value = cityInput.community
  renewOffers(fraction.value?.empto_id as number, cityInput.zip)
}

const renewOffers = (emptoFractionId: number, zipCode: string) => {
  findOffer(emptoFractionId, zipCode)
    .then((response: any) => {
      if (!response || !response.length || response?.length < 1) {
        offerStore.setCurrentOffers([])
        acceptNewPrice()
        return
      }
      const offers = [] as ContainerOffer[]
      for (const offer of response) {
        const foundContainer = containers.value?.find(
          container => container.empto_id === offer.containerId,
        ) as Container
        const offerGrossPriceInCent = convertToCentNumber(Number(offer.grossPrice))
        const offerNetPriceInCent = calculatePriceWithouVatInCent(
          offerGrossPriceInCent,
          Number(offer.vat),
        )
        const finalOffer: ContainerOffer = {
          directusContainerId: foundContainer.id,
          emptoContainerId: offer.containerId,
          directusFractionId: fraction.value!.id,
          emptoFractionId: emptoFractionId,
          offerId: offer.offerId,
          vatPercentage: offer.vat,
          unitPrice: {
            netPrice: offerNetPriceInCent,
            grossPrice: offerGrossPriceInCent,
            volumePrice: convertToCentNumber(Number(offer.volumePrice)),
            vat: offerGrossPriceInCent - offerNetPriceInCent,
          },
          productHeight: offer.productHeight,
          productLength: offer.productLength,
          productWidth: offer.productWidth,
          productSize: offer.productSize,
          productSpace: offer.productSpace,
          description: foundContainer.description,
          size: foundContainer.size,
          space: foundContainer.space,
          dimension: foundContainer.dimension,
          image: foundContainer.image,
        }
        offers.push(finalOffer)
      }
      if (offers.length < 1) {
        offerStore.setCurrentOffers([])
        acceptNewPrice()
        return
      }
      newOffers.value = offers
    })
    .catch(() => {
      offerStore.setCurrentOffers([])
      acceptNewPrice()
    })
}

const activeOffers = computed(() => {
  if (newOffers.value.length > 0) {
    return newOffers.value
  }
  return currentOffers.value
})
const activeCity = computed(() => {
  if (newCity.value !== '') {
    return newCity.value
  }
  return currentCity.value?.name
})
const priceChangeByReason = computed(() => {
  const newOffer = newOffers.value?.find(
    container => container.directusContainerId === selectedContainer.value,
  )
  if (newOffer) {
    const newPrice = newOffer?.unitPrice.grossPrice
    const oldPrice = currentOffers.value?.find(
      container => container.directusContainerId === selectedContainer.value,
    )?.unitPrice.grossPrice
    if (newZipcode.value !== currentCity.value?.zip) {
      if (newPrice && oldPrice && newPrice !== oldPrice) {
        return { flag: true, reason: 1 }
      }
    } else if (
      newFractionId.value &&
      newFractionId.value !== currentFractionId.value &&
      newFractionId.value > 0
    ) {
      return { flag: true, reason: 2 }
    }
  }
  return { flag: false, reason: 0 }
})

const acceptNewPrice = () => {
  offerStore.setCurrentCity({
    zip: `${newZipcode.value}`,
    name: `${newCity.value}`,
    community: Number(`${newCommunity.value}`),
  })

  if (
    newFractionId.value &&
    newFractionId.value !== currentFractionId.value &&
    newFractionId.value > 0
  ) {
    offerStore.setCurrentFractionId(newFractionId.value)
  }

  if (selectedContainer.value > 0) {
    selectedOffer.value =
      newOffers.value.find(
        container => container.directusContainerId === selectedContainer.value,
      ) || null
  }

  offerStore.setCurrentOffers(JSON.parse(JSON.stringify(newOffers.value)))
  newOffers.value = []
  newCity.value = ''
  newZipcode.value = ''
  newCommunity.value = undefined
}
const declineNewPrice = () => {
  newOffers.value = []
  newCity.value = ''
  newZipcode.value = ''
}

const validateCurrentOffer = async (): Promise<boolean> => {
  try {
    if (!selectedOffer.value) throw new Error('Selected offer is not defined')
    if (!deliveryDate.value || deliveryDate.value.length < 2)
      throw new Error('Invalid delivery date')
    if (!containerPlacementTime.value) throw new Error('Container placement time is not defined')

    if (!selectedOffer.value.directusContainerId || selectedOffer.value.directusContainerId <= 0)
      throw new Error('Invalid directus container ID')
    if (!selectedOffer.value.emptoContainerId || selectedOffer.value.emptoContainerId <= 0)
      throw new Error('Invalid empto container ID')
    if (!selectedOffer.value.directusFractionId || selectedOffer.value.directusFractionId <= 0)
      throw new Error('Invalid directus fraction ID')
    if (!selectedOffer.value.emptoFractionId || selectedOffer.value.emptoFractionId <= 0)
      throw new Error('Invalid empto fraction ID')

    if (!selectedOffer.value.offerId || selectedOffer.value.offerId <= 0)
      throw new Error('Invalid offer ID')

    if (selectedOffer.value.vatPercentage < 0) throw new Error('Invalid VAT percentage')

    if (
      !selectedOffer.value.unitPrice ||
      Object.values(selectedOffer.value.unitPrice).filter(item => item !== 0).length < 3
    )
      throw new Error('Invalid unit price')

    if (!selectedOffer.value.productHeight || selectedOffer.value.productHeight <= 0)
      throw new Error('Invalid product height')
    if (!selectedOffer.value.productLength || selectedOffer.value.productLength <= 0)
      throw new Error('Invalid product length')
    if (!selectedOffer.value.productSize || selectedOffer.value.productSize <= 0)
      throw new Error('Invalid product size')
    if (!selectedOffer.value.productSpace || selectedOffer.value.productSpace <= 0)
      throw new Error('Invalid product space')
    if (!selectedOffer.value.productWidth || selectedOffer.value.productWidth <= 0)
      throw new Error('Invalid product width')
    if (!selectedOffer.value.description || selectedOffer.value.description.length < 1)
      throw new Error('Invalid description')

    return true
  } catch (error: any) {
    console.error(`Validation failed: ${error.message}`)

    return false
  }
}

const addItemToCart = async () => {
  validatedOnce.value = true
  if (!selectedOffer.value) return

  if (cartHasProductWithDifferentZip.value) {
    showCustomerOrdersWithDifferentZipModal.value = true
    return
  }

  if (currentContainerHasReachedMaxQuantity.value) return

  const isValid = await validateCurrentOffer()

  if (!isValid) return

  const cartItem: Product = {
    quantity: 1,
    ...selectedOffer.value,
    deliveryDate: [deliveryDate.value[0], deliveryDate.value[deliveryDate.value.length - 1]],
    containerPlacementTime: containerPlacementTime.value,
    extraRentFeeNett: productRentAmount.value.nett,
    extraRentFeeGross: productRentAmount.value.gross,
  }

  cartStore.addProductToCart(cartItem)

  cartStore.addDeliveryAddressToCart({
    phoneNumber: '',
    street: '',
    houseNumber: '',
    postalCode: currentCity.value?.zip ?? '',
    city: currentCity.value?.name ?? '',
    deliveryNotes: '',
    positionOnPublicGround: false,
  })

  sidebarStore.openGlobalSidebar(ACTIVE_SIDEBAR_TYPE.CART)
}

const currentContainerHasReachedMaxQuantity = computed(() => {
  const product = cart.value.products.find(
    product => product.offerId === selectedOffer.value?.offerId,
  )
  return (product?.quantity ?? 0) >= MAX_PRODUCT_QUANTITY
})

const noDeliveryDateOrWrongFormat = computed(() => {
  return !deliveryDate.value || deliveryDate.value.length < 2
})

const disableToolTip = computed(() => {
  return !currentContainerHasReachedMaxQuantity.value && !noDeliveryDateOrWrongFormat.value
})

const productRentAmount = computed(() => {
  if (
    unitRentPerWeek.value &&
    taxPercent.value &&
    deliveryDate.value &&
    deliveryDate.value.length > 0
  ) {
    const endDate = new Date(deliveryDate.value[deliveryDate.value.length - 1])
    const startDate = new Date(deliveryDate.value[0])
    const durationInTime = endDate.getTime() - startDate.getTime()

    const durationInDays = Math.ceil(durationInTime / MILLISECONDS_PER_DAY) - 1
    if (durationInDays > 14) {
      const chargedDays = durationInDays - 14
      const chargedWeeks = Math.ceil(chargedDays / 7)
      const rent = unitRentPerWeek.value * chargedWeeks
      const tax = (rent * taxPercent.value) / 100
      return { nett: rent, tax: tax, gross: Math.round(rent + tax) }
    } else {
      return { nett: 0, tax: 0, gross: 0 }
    }
  }
  return { nett: 0, tax: 0, gross: 0 }
})

watch(newOffers, newValue => {
  if (newValue.length > 0 && newZipcode.value !== currentCity.value?.zip) {
    const newOffer = newValue.find(
      container => container.directusContainerId === selectedContainer.value,
    )
    if (!newOffer) {
      selectedContainer.value = newValue[0].directusContainerId
      acceptNewPrice()
    }
    const newPrice = newOffer?.unitPrice.grossPrice
    const oldPrice = currentOffers.value?.find(
      container => container.directusContainerId === selectedContainer.value,
    )?.unitPrice.grossPrice
    if (newPrice && oldPrice && newPrice === oldPrice) {
      acceptNewPrice()
    }
  }
})
watch(currentOffers, newValue => {
  if (
    newValue.length > 0 &&
    !newValue.find(container => container.directusContainerId === selectedContainer.value)
  ) {
    selectedContainer.value = currentOffers.value[0].directusContainerId
  }
  selectProduct(selectedContainer.value)
})
watch(priceChangeByReason, newValue => {
  if (newValue.flag === true) {
    const newOffersString = JSON.stringify(newOffers.value)
    priceDiffObject.value = JSON.parse(newOffersString)
  }
})

onBeforeMount(() => {
  if (
    selectedContainer.value === 0 ||
    !currentOffers.value.find(
      container => container.directusContainerId === selectedContainer.value,
    )
  ) {
    selectedContainer.value = currentOffers.value[0].directusContainerId
  }
  selectProduct(selectedContainer.value)
})
</script>

<style lang="scss" scoped>
.border-sm.border-error {
  border-color: rgba(var(--v-border-color), 1) !important;
}

.front-icon {
  z-index: 100;
}

.sample {
  font-size: 14px;
}

.cubic-price {
  font-size: 10px;
}

.add-to-cart:deep(span) {
  white-space: wrap !important;
}

.hero-input :deep(input) {
  text-align: center !important;
}

.active-container {
  border: 2px solid rgb(var(--v-theme-primary)) !important;
}

.limited {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.too-long {
  font-size: 12px;
}
span.position-absolute {
  top: 18px !important;
}
</style>
